<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header>
            Product configuration propositions
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
        ></data-table>
        <template v-if="Object.keys(draft).length">
            <mercur-dialog :is-open.sync="isPopup">
                <div>Are you sure you want to {{draft.text}} this proposition</div>
                <div slot="footer">
                    <button class="btn btn-primary">Cancel</button>
                    <button @click="draft.method" class="btn btn-yellow">Submit</button>
                </div>
            </mercur-dialog>
        </template>
        <div v-if="detail">
            <mercur-dialog :is-open.sync="showDetail">
                <div>
                    <h3>Proposed changes for {{ detail.productConfigurationName }}:</h3>
                    <div v-for="(values, key) in detail.changeProposition" :key="key">
                        <p><strong>Attribute: {{ key }}</strong></p>
                        <ul v-for="value in values" :key="value.option">
                            <li v-if="value.type === 'deletion'">Type: <span class="color-red">Deletion</span></li>
                            <li v-else>Type: <span class="color-green">Addition</span></li>
                            <li>Option: {{ value.option }}</li>
                            <li>Alternative: {{ value.alternative }}</li>
                        </ul>
                    </div>
                </div>
            </mercur-dialog>
        </div>
    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'SupplierProductConfigurationPropositions',
    components: { DataTable, GridHeader },
    data () {
        return {
            isPopup: false,
            draft: {},
            loading: false,
            detail: null,
            showDetail: false,
            options: {
                columns: {
                    'Product configuration name': {
                        field: 'productConfigurationName',
                    },
                    'Change Proposition': {
                        field: 'changeProposition',
                        valueGetter: ({ data }) => {
                            if (!data.changeProposition) {
                                return '-'
                            }
                            return Object.keys(data.changeProposition).join(', ')
                        },
                    },
                    '': {
                        field: '',
                        cellRendererFramework: 'ActionsCell',
                        width: 60,
                        cellRendererParams: () => {
                            return {
                                actions: [
                                    {
                                        isIcon: true,
                                        text: 'See detail',
                                        tooltipText: 'See detail',
                                        icon: 'fas fa-eye',
                                        onClick: ({ data }) => {
                                            this.showDetail = true
                                            this.$set(this, 'detail', data)
                                        },
                                    },
                                ],
                            }
                        },
                    },
                    'Proposition status': {
                        field: 'propositionStatus',
                        statusChip: true,
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                },
                actions: [
                    {
                        isIcon: true,
                        text: 'Accept',
                        tooltipText: 'Accept',
                        icon: 'fas fa-check',
                        onClick: ({ data }) => {
                            this.isPopup = true
                            this.$set(this, 'draft', {
                                text: 'accept',
                                data: data,
                                method: this.acceptProposition,
                            })
                        },
                        disabled: () => this.loading,
                    },
                    {
                        isIcon: true,
                        text: 'Reject',
                        tooltipText: 'Reject',
                        icon: 'fas fa-trash',
                        disabled: () => this.loading,
                        onClick: ({ data }) => {
                            this.isPopup = true
                            this.$set(this, 'draft', {
                                text: 'reject',
                                data: data,
                                method: this.rejectProposition,
                            })
                        },
                    },
                ],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES_FOR_APPROVAL.LIST_CONFIGURATION_PROPOSITIONS
                .replace('{supplierId}', this.supplierId)
                .replace('{productConfigurationId}', this.$route.params.productConfigurationId)
        },
    },
    methods: {
        acceptProposition () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES_FOR_APPROVAL.ACCEPT_PROPOSITION
                .replace('{supplierId}', this.supplierId)
                .replace('{productConfigurationPropositionId}', this.draft.data.productConfigurationPropositionId)
            this.callAction(url)
        },
        rejectProposition () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES_FOR_APPROVAL.REJECT_PROPOSITION
                .replace('{supplierId}', this.supplierId)
                .replace('{productConfigurationPropositionId}', this.draft.data.productConfigurationPropositionId)
            this.callAction(url)
        },
        callAction (url) {
            this.loading = true
            this.$api.post(url, {}).then(() => {
                this.$refs.grid.refreshGrid()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>
.color-red {
    color: red
}
.color-green {
    color: green
}
</style>
